import { http } from './http'

export default {
  resetPassword(ids: {password: string; username: string}): Promise<{}> {
    return http.post('/charge-unite/reset-password', ids)
  },
  getUnites(login: string): Promise<void> {
    return http.get('/charge-unite/unités?login=' + login)
  },
}
