import { MutationTree } from 'vuex'
import { UserState } from './types'

export const mutations: MutationTree<UserState> = {
  LOG_USER(state, response): void {
    if (response.data.tokens) {
      const validity = Date.now() + (5 * 60 * 1000)
      localStorage.setItem('tokenValidity', JSON.stringify(validity))
      state.tokenValidity = validity
      localStorage.setItem('accessToken', response.data.tokens.accessToken)
      state.accessToken = response.data.tokens.accessToken
      localStorage.setItem('refreshToken', response.data.tokens.refreshToken)
      state.refreshToken = response.data.tokens.refreshToken
    }
    localStorage.setItem('username', response.data.username)
    state.username = response.data.username
  },
  LOGOUT(state): void {
    localStorage.clear()
    state.username = ''
    state.role = ''
  },
  REFRESH(state, response): void {
    const validity = Date.now() + (5 * 60 * 1000)
    localStorage.setItem('tokenValidity', JSON.stringify(validity))
    state.tokenValidity = validity
    state.accessToken = response.data.accessToken
    localStorage.setItem('accessToken', response.data.accessToken)
    state.refreshToken = response.data.refreshToken
    localStorage.setItem('refreshToken', response.data.refreshToken)
  },
  GET_ROLES(state, response): void {
    state.role = response.data
    localStorage.setItem('role', response.data)
  },
  GET_UNITES(state, response): void {
    state.unites = response.data
    localStorage.setItem('unites', response.data)
  },
  LOG_AS_ETAB(state): void {
    state.role = 'USER'
    localStorage.setItem('role', 'USER')
  },
  SAVE_SEARCH_REQUEST(state, param): void {
    state.searchRequest = param
    localStorage.setItem('searchRequest', JSON.stringify(param))
  },
  CLEAR_SEARCH_REQUEST(state): void {
    state.searchRequest = {}
    localStorage.removeItem('searchRequest')
  }
}
