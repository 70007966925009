import { Identifiants } from '@/models/Identifiants'
import axios from 'axios'
import { http } from './http'

export default {
  login(ids: Identifiants): Promise<{}> {
    return axios.post(process.env.VUE_APP_API + '/authentication/login', ids)
  },
  getMe(): Promise<{}> {
    return http.get('/authentication/me')
  },
  getRoles(): Promise<{}> {
    return http.get('/authentication/roles')
  },
  refresh(request: {token: string}): Promise<{}> {
    return axios.post(process.env.VUE_APP_API + '/authentication/refresh', request)
  },
  createResetPasswordKey(email: string, firstName: string, lastName: string): Promise<{}> {
    return axios.post(process.env.VUE_APP_API + '/authentication/reset-password', {email, firstName, lastName})
  },
  resetPasswordKeyExist(key: string): Promise<{}> {
    return http.get(process.env.VUE_APP_API + '/authentication/reset-password/' + key)
  },
  useResetPasswordKey(key: string, newPassword: string): Promise<{}> {
    return axios.post(process.env.VUE_APP_API + '/authentication/reset-password/' + key, {
      password: newPassword
    })
  }
}
