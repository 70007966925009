import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { Module } from 'vuex'
import { RootState } from '@/stores/types'
import { ChargeUniteState } from './types'

export const state: ChargeUniteState = {
  unites: [],
}

const namespaced = true

export const chargeUnite: Module<ChargeUniteState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
