import { UserState } from './types'
import { ActionTree } from 'vuex'
import { RootState } from '@/stores/types'
import { Identifiants } from '@/models/Identifiants'

import loginApi from '@/api/login.api'

export const actions: ActionTree<UserState, RootState> = {

  login({ commit }, ids: Identifiants): Promise<void|{}> {
    return loginApi.login(ids).then((loginResponse) => {
      commit('LOG_USER', loginResponse)
    })
  },
  logout({ commit }): void {
    commit('LOGOUT')
    commit('admin/LOGOUT', null, { root: true })
  },
  refreshToken({ commit }, token: string): Promise<void|{}> {
    const request = {
      'token': token
    }
    return loginApi.refresh(request).then((refreshResponse) => {
      commit('REFRESH', refreshResponse)
    })
  },
  getMe({ commit }): Promise<void|{}> {
    return loginApi.getMe().then((getMeResponse) => {
      commit('GET_ME', getMeResponse)
    })
  },
  getRoles({ commit }): Promise<void|{}> {
    return loginApi.getRoles().then((getRolesResponse) => {
      commit('GET_ROLES', getRolesResponse)
    })
  },
  logAsEtab({ commit }): void {
    commit('LOG_AS_ETAB')
  },
  saveSearchRequest({ commit }, request: {}): void {
    commit('SAVE_SEARCH_REQUEST', request)
  },
  clearSearchRequest({ commit }): void {
    commit('CLEAR_SEARCH_REQUEST')
  }
}
