
































































































































import { Vue, Component} from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { Commande } from '@/models/Commande'
import { Etablissement } from '@/models/Etablissement'
import { Livre } from '@/models/Livre'

import AdminPanel from '@/components/AdminPanel.vue'
import Alert from '@/components/Alert.vue'
import AdresseEtablissement from "@/components/AdresseEtablissement.vue"
import RechercheEtablissements from "@/components/RechercheEtablissements.vue"

@Component({
  components: {
    AdminPanel,
    Alert,
    AdresseEtablissement,
    RechercheEtablissements
  }
})
export default class LivraisonDetails extends Vue {
  @Action('setSelectedCommande', { namespace: 'commande' }) public setSelectedCommande!: Function
  @Action('devisByNiveau',{namespace: 'commande'}) public devisByNiveau!: Function
  
  @Getter('etablissement', { namespace: 'etablissement' }) public etablissement!: Etablissement
  @Getter('selectedCommande', { namespace: 'commande' }) public selectedCommande!: Commande
  @Getter('livres',{ namespace: 'commande' }) public livres!: Livre[]
  @Getter('role', { namespace: 'user' }) public role!: string

  public $refs: any = {}

  get packType() {
    
    if (this.selectedCommande.packType === 'SPL') {
      return 'Solution Pack Livres'
    }
    if (this.selectedCommande.packType === 'SPN') {
      return 'Solution Pack Numérique'
    }
    if (this.selectedCommande.packType === 'SSA') {
      return 'Solution Sac Allégé'
    }
    if (this.selectedCommande.packType === 'SSAN') {
      return 'Solution Sac Allégé Numérique'
    }
    return ''
  }

  public formatDate(date: Date): string {
    const options = { year: "numeric", month: "long", day: "numeric" }
    const readableDate = new Date(date).toLocaleDateString(undefined, options)
    return readableDate
  }

  
  public toRevente(): void { 
       this.$router.push({name:'Revente-details'}) 
  }

  dateCommandeChecker(date: string): boolean {
    if (Date.parse(date) < Date.now()) {
      return true
    } else {
      return false
    }
  }

  public goToDevis(etablissementID: string, niveau: string): void {
    let errorWs = false
     const requestLivraison = {
      etablissementId: etablissementID,
      niveau: niveau  
    }
    this.devisByNiveau(requestLivraison).catch(() => {
      errorWs = true
      this.$refs.snackbar.open(`Une erreur est survenue lors du devis.`)
    }).finally(() => {
      if (!errorWs) this.$router.push({name:'Devis-classe'})
    })
  }

}
