import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { Module } from 'vuex'
import { RootState } from '@/stores/types'
import { EtablissementState } from './types'

import { Etablissement } from '@/models/Etablissement'

export const state: EtablissementState = {
  activites: [],
  etablissement: new Etablissement,
  etablissements: [],
  groupement: [],
  niveaux: []
}

const namespaced = true

export const etablissement: Module<EtablissementState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
