import { http } from './http'

export default {
  getElevesNoms(groupementId: string): Promise<{}> {
    const params = new URLSearchParams();
    params.append("groupement", groupementId.toString());
    return http.post('/eleve/noms', params)
  },
  getElevesPrenoms(groupementId: string): Promise<{}> {
    const params = new URLSearchParams();
    params.append("groupement", groupementId.toString());
    return http.post('/eleve/prenoms', params)
  },
  getEtablissementNiveaux(groupementId: string): Promise<{}> {
    const params = new URLSearchParams();
    params.append("groupement", groupementId.toString());
    return http.post('/eleve/niveaux', params)
  },
  getEtablissementAnnees(groupementId: string): Promise<{}> {
    const params = new URLSearchParams();
    params.append("groupement", groupementId.toString());
    return http.post('/eleve/annee-scolaire', params)
  }
}
