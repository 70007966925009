






































































import {Vue, Component, Watch} from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import {Etablissement} from "@/models/Etablissement";
import EventBus from "@/plugins/bus";

@Component({
  components: {}
})
export default class Header extends Vue {
  @Getter('isAuthenticated', { namespace: 'user' }) public isAuthenticated?: boolean
  @Getter('role', { namespace: 'user' }) public role!: string
  @Action('logout', { namespace: 'user' }) public logout!: Function

  @Action('notifsDocuments', { namespace: 'document' }) public notifsDocuments!: Function
  @Getter('etablissement', { namespace: 'etablissement' }) public etab!: Etablissement

  public notifs = 0

  public mounted() {
    this.loadNotifications();
  }

  public created() {
    EventBus.$on('refresh_documents', this.loadNotifications)
  }

  // Chargement des notifications lors de la connexion
  @Watch('etab')
  public onIsAuthenticatedChanged() {
    this.loadNotifications();
  }

  public loadNotifications() {
    if (this.role == 'USER') {
      this.notifsDocuments({id: this.etab.id}).then((res) => {
        this.notifs = res.data;
      })
    }
  }

  public goLogOut(): void {
    this.logout()
    this.$router.push({name:'Login'})
  }

  public goDocuments(): void {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.$router.push({name:'Documents'}, () => {this.loadNotifications()})
  }

}
