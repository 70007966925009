import { AdminState } from './types'
import { RootState } from '@/stores/types'
import { GetterTree } from 'vuex'

export const getters: GetterTree<AdminState, RootState> = {
    groupementsAdmin(state): string[] {
      if (!state.groupementsAdmin) {
        localStorage.setItem('groupementsAdmin', JSON.stringify(state.groupementsAdmin))
        return state.groupementsAdmin!
      }
      return JSON.parse(localStorage.getItem('groupementsAdmin')!)
    },
    searchRequestAdmin(state): {} | null{
      if (!state.searchRequestAdmin) {
        localStorage.setItem('searchRequestAdmin', JSON.stringify(state.searchRequestAdmin))
        return state.searchRequestAdmin!
      }
      return JSON.parse(localStorage.getItem('searchRequestAdmin')!)

    }
}
