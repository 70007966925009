




import { Component, Prop, Vue } from 'vue-property-decorator'
import { Chart } from 'chart.js'

@Component
export default class Bars extends Vue {
  @Prop() labels!: Array<string>
  @Prop() dataset!: Array<{}>
  @Prop({
    default: () => {
      return Chart.defaults.bar
    }
  })

  mounted(): void {
    this.createChart({
      labels: this.labels,
      datasets: this.dataset
    })
  }

  createChart(chartData: object) {
    const canvas = document.getElementById('bar') as HTMLCanvasElement
    const options: object = {
      type: 'bar',
      data: chartData,
      options: {
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        },
        legend: {
          display: true,
          position: 'bottom'
        }
      }
    }
    new Chart(canvas, options)
  }
}
