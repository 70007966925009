









import { Vue, Component, Prop } from 'vue-property-decorator'
import { Etablissement } from '@/models/Etablissement'

@Component({
  components: {}
})
export default class AdresseEtablissement extends Vue {

@Prop() etablissement!: Etablissement

}
