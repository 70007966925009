










































































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Commande } from "@/models/Commande";
import { Etablissement } from "@/models/Etablissement";
import AdminPanel from "@/components/AdminPanel.vue";
import Alert from "@/components/Alert.vue";
import Loader from "@/components/FullScreenLoader.vue";
import AdresseEtablissement from "@/components/AdresseEtablissement.vue";
import RechercheEtablissements from "@/components/RechercheEtablissements.vue";
import moment from "moment";

@Component({
  components: {
    AdminPanel,
    Alert,
    AdresseEtablissement,
    Loader,
    RechercheEtablissements,
  },
})
export default class Recherche extends Vue {
  @Action("setSelectedCommande", { namespace: "commande" })
  public setSelectedCommande!: Function;
  @Action("livres", { namespace: "commande" }) public livres!: Function;
  @Action("devisByNiveau", { namespace: "commande" })
  public devisByNiveau!: Function;

  @Getter("etablissement", { namespace: "etablissement" })
  public etablissement!: Etablissement;
  @Getter("commandes", { namespace: "commande" })
  public commandes!: Commande[];
  @Getter("role", { namespace: "user" }) public role!: string;
  @Getter("searchRequest", { namespace: "user" }) public searchRequest!: any;

  @Action("exportXLS", { namespace: "commande" }) public export!: Function;

  public currentTab = 0;
  public $refs: any = {};
  public loading = false;
  public exportLoad = false;
  public loadingMsg = "Recherche en cours...";

  @Watch("searchRequest")
  public searchChanged() {
    const date = new Date();

    // 16 mai
    const startReventeDate = new Date(date.getFullYear(), 4, 16);
    // 14 aout
    const endReventeDate = new Date(date.getFullYear(), 7, 14);

    if (date >= startReventeDate && date <= endReventeDate) {
      // Selection des reventes par défaut.
      this.currentTab = 1;
    } else {
      // Selection des livraisons par défaut.
      this.currentTab = 0;
    }

    if (
      this.searchRequest.statutLivraison != null &&
      this.searchRequest.statutLivraison != ""
    ) {
      this.currentTab = 0;
    }
    if (
      this.searchRequest.statutRevente != null &&
      this.searchRequest.statutRevente != ""
    ) {
      this.currentTab = 1;
    }
  }

  public created() {
    // 0060058: ARBS - TMA - Site Etab - Evolution - Affichage prioritaire des opérations de Livraison et de Revente selon une date
    const date = new Date();

    // 16 mai
    const startReventeDate = new Date(date.getFullYear(), 4, 16);
    // 14 aout
    const endReventeDate = new Date(date.getFullYear(), 7, 14);

    if (date >= startReventeDate && date <= endReventeDate) {
      // Selection des reventes par défaut.
      this.currentTab = 1;
    } else {
      // Selection des livraisons par défaut.
      this.currentTab = 0;
    }

    if (
      this.searchRequest.statutLivraison != null &&
      this.searchRequest.statutLivraison != ""
    ) {
      this.currentTab = 0;
    }
    if (
      this.searchRequest.statutRevente != null &&
      this.searchRequest.statutRevente != ""
    ) {
      this.currentTab = 1;
    }
  }

  public exportXLS(): any {
    this.exportLoad = true;
    this.export(this.searchRequest)
      .catch(() => {
        this.$refs.snackbar.open(
          "une erreur est survenue lors de la recherche des commandes. Veuillez réessayer."
        );
      })
      .then((res) => {
        if (res.status === 200) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "commandes.xls");
          document.body.appendChild(link);
          link.click();
        } else {
          this.$refs.snackbar.open(
            "une erreur est survenue lors de l'export des commandes. Veuillez réessayer."
          );
        }
      })
      .finally(() => {
        this.exportLoad = false;
      });
  }

  public livraisonsChecker(livraison: Commande): string {
    if (livraison.statut_livraison == 1) {
      return "success";
    } else if (
      livraison.statut_livraison! > 0 &&
      livraison.statut_livraison! < 1
    ) {
      return "warning";
    } else {
      return "error";
    }
  }

  public reventeChecker(revente: Commande): string {
    if (revente.statut_revente == 1) {
      return "success";
    } else if (revente.statut_revente! > 0 && revente.statut_revente! < 1) {
      return "warning";
    } else {
      return "error";
    }
  }

  public formatDate(date: Date): string {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const readableDate = new Date(date).toLocaleDateString(undefined, options);
    return readableDate;
  }

  public formatBirthday(date: Date): string {
    return date != null
      ? moment(date)
          .format("DD/MM/YYYY")
          .toString()
      : "N/A";
  }

  public goToDevis(etablissementID: string, niveau: string): void {
    let errorWs = false;
    const requestLivraison = {
      etablissementId: etablissementID,
      niveau: niveau,
    };
    this.devisByNiveau(requestLivraison)
      .catch(() => {
        errorWs = true;
        this.$refs.snackbar.open(`Une erreur est survenue lors du devis.`);
      })
      .finally(() => {
        if (!errorWs) this.$router.push({ name: "Devis-classe" });
      });
  }

  public goToCommande(commande: Commande, type: string): void {
    let errorWs = false;
    this.setSelectedCommande(commande);
    this.livres(commande.numeroCommande)
      .catch(() => {
        errorWs = true;
        this.$refs.snackbar.open(
          `Une erreur est survenue lors de la récupération des livres.`
        );
      })
      .finally(() => {
        if (type === "livraison") {
          if (!errorWs) this.$router.push({ name: "Livraison-details" });
        } else {
          if (!errorWs) this.$router.push({ name: "Revente-details" });
        }
      });
  }
}
