var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"Agenda"},[_c('div',{staticClass:"Agenda-leftBar"},[(_vm.role === 'ADMIN' || _vm.role === 'CHARGE_UNITE')?_c('AdminPanel'):_vm._e(),_c('AdresseEtablissement',{attrs:{"etablissement":_vm.etablissement}}),_c('div',{staticClass:"Agenda-leftBar-link box"},[_c('router-link',{attrs:{"to":{ name: 'Accueil' }}},[_c('v-btn',{staticClass:"box",attrs:{"large":"","tile":"","depressed":"","color":"secondary"}},[_vm._v(" Accueil ")])],1)],1),_c('div',{staticClass:"box"},[_c('RechercheEtablissements')],1)],1),_c('div',{staticClass:"Agenda-mainSection largeArea"},[_c('h1',{staticClass:"Agenda-mainSection-title"},[_vm._v("Agenda")]),_c('v-data-table',{staticClass:"v-virtual-scroll elevation-1 mt-8",staticStyle:{"height":"calc(100vh - 280px)"},attrs:{"headers":_vm.headers,"items":_vm.activites,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.dateDebut))+" ")]}},{key:"item.libelle",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.color}},[_vm._v(" "+_vm._s(item.libelle)+" ")])]}},{key:"item.dateDebut",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.prettyTime(item.dateDebut))+" ")]}},{key:"item.dateFin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.prettyTime(item.dateFin))+" ")]}}],null,true)},[_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}),_c('p',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.loadingMsg))])],1)],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }