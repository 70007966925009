











































































































































































































































import { Component } from "vue-property-decorator";
import Alert from "@/components/Alert.vue";
import FormMixin from "@/mixins/FormMixin";
import { Action, Getter } from "vuex-class";
import AdminApi from "@/api/admin.api";
import { Etablissement } from "@/models/Etablissement";
import { Document } from "@/models/Document";
import Loader from "@/components/FullScreenLoader.vue";
import EventBus from "@/plugins/bus";

@Component({
  components: {
    Alert,
    Loader,
  },
})
export default class Documents extends FormMixin {
  @Action("uploadDocument", { namespace: "document" })
  public uploadDocument!: Function;
  @Action("editDocument", { namespace: "document" })
  public editDocument!: Function;
  @Action("getDocuments", { namespace: "document" })
  public getDocuments!: Function;
  @Action("seeDocument", { namespace: "document" })
  public seeDocument!: Function;
  @Action("deleteDocument", { namespace: "document" })
  public deleteDocument!: Function;
  @Getter("role", { namespace: "user" }) public role?: string;
  @Getter("unites", { namespace: "chargeUnite" }) public unite?: string[];
  @Getter("etablissement", { namespace: "etablissement" })
  public etab!: Etablissement;

  public file: any = null;
  public fileName = "";
  public etablissements = [];

  public loading = true;
  public loadingMsg = "Chargement des documents en cours...";

  public documents: Document[] = [];

  public searchResults: Etablissement[] = [];
  public listSize = 0;
  public selectedEdit: Document | null = null;

  public $refs: any = {};

  public getEtablissementText(item: any) {
    return item.id + " - " + item.nom;
  }

  public downloadFile(file: Document) {
    this.loading = true;
    this.loadingMsg = "Téléchargement en cours...";
    this.seeDocument({
      id: file.id,
      etablissement: this.role === "USER" ? this.etab.id : "all",
    })
      .then((res) => {
        const data =
          "data:application/octet-stream;charset=utf-16le;base64," + res.data;
        const link = document.createElement("a");
        link.setAttribute(
          "download",
          file.nom + "." + file.fileType?.split("/")[1]
        );
        link.setAttribute("href", data);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        EventBus.$emit("refresh_documents");
      })
      .finally(() => {
        this.load();
        this.loading = false;
      });
  }

  public deleteFile(file: Document) {
    this.loading = true;
    this.loadingMsg = "Suppression en cours...";
    this.deleteDocument({ id: file.id }).then(() => {
      this.load();
    });
  }

  public editFile(file: Document) {
    this.loading = true;
    this.loadingMsg = "Modification en cours...";
    this.selectedEdit = file;
    this.selectedEdit.etablissements =
      file.seens?.map((seen: any) => seen.etablissement.id) ?? [];
    // order etablissements by name
    this.selectedEdit.etablissements.sort((a: any, b: any) => {
      return a.localeCompare(b);
    });
    this.loading = false;
  }

  public cancelEdit() {
    this.selectedEdit = null;
    this.load();
    this.fileName = "";
    this.$refs.documentForm.reset();
  }

  public saveEdit() {
    this.loading = true;
    this.loadingMsg = "Enregistrement en cours...";
    this.editDocument({
      id: this.selectedEdit!.id,
      etablissements: this.selectedEdit!.etablissements,
      nom: this.selectedEdit!.nom,
    })
      .then(() => {
        this.load();
        this.fileName = "";
      })
      .finally(() => {
        this.loading = false;
        this.selectedEdit = null;
        this.$refs.documentForm.reset();
      });
  }

  public goHome() {
    this.$router.push("/");
  }

  public validate(): void {
    if (this.$refs.documentForm.validate()) {
      this.loading = true;
      this.loadingMsg = "Ajout du document en cours...";
      this.uploadDocument({
        etablissement: this.etablissements,
        nom: this.fileName,
        file: this.file,
      })
        .then(() => {
          this.etablissements = [];
          this.file = null;
          this.fileName = "";
        })
        .finally(() => {
          this.load();
          this.$refs.documentForm.reset();
        });
    }
  }

  public formatDate(date: Date): string {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const readableDate = new Date(date).toLocaleDateString(undefined, options);
    return readableDate;
  }

  public mounted(): void {
    this.load();
  }

  public load() {
    this.loading = true;
    this.loadingMsg = "Chargement des documents en cours...";
    if (this.role !== "USER") {
      this.rechercheEtablissement();
    } else {
      let docRequest = {} as any;
      docRequest = {
        etablissements: this.etab.id,
      };
      this.getDocuments(docRequest)
        .then((res) => {
          this.documents = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  public rechercheEtablissement() {
    let request = {} as any;
    if (this.role === "CHARGE_UNITE") {
      request = {
        unites: this.unite,
      };
    }
    this.searchResults = [];
    AdminApi.rechercheEtablissement(request)
      .then((response: any) => {
        this.listSize = response.data.length;
        this.searchResults = response.data;
      })
      .catch(() => {
        this.$refs.snackbar.open(
          `Une erreur est survenue lors de la recherche d'établissement. Veuillez réessayer.`
        );
      })
      .finally(() => {
        let docRequest = {} as any;
        docRequest = {
          etablissements: this.searchResults.map((e) => e.id).join(","),
        };
        this.getDocuments(docRequest)
          .then((res) => {
            this.documents = res.data;
          })
          .finally(() => {
            this.loading = false;
          });
      });
  }
}
