import { AdminState } from './types'
import { ActionTree } from 'vuex'
import { RootState } from '@/stores/types'
import AdminApi from '@/api/admin.api'

export const actions: ActionTree<AdminState, RootState> = {
  getGroupements({ commit }): Promise<void|{}> {
    return AdminApi.getGroupements().then((getResponse) => {
      commit('GET_GROUPEMENTS_ADMIN', getResponse)
    })
  },
  updateGroupement({ commit }, updateRequest: {groupement: string; id: string}): Promise<void|{}> {
    return AdminApi.updateGroupement(updateRequest)
  },
  saveSearchRequestAdmin({ commit }, request: {}): void {
    commit('SET_ADMIN_REQUEST', request)
  }
}
