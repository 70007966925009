import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { Module } from 'vuex'
import { RootState } from '@/stores/types'
import { AdminState } from './types'

export const state: AdminState = {
  groupementsAdmin: [],
  searchRequestAdmin: {}
}

const namespaced = true

export const admin: Module<AdminState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
