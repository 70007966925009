import axios from 'axios'
import router from '../router'
import Store from '@/stores/store'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API
})

instance.interceptors.request.use(async (config) => {

  if (parseInt(localStorage.getItem('tokenValidity')!) < Date.now()) {
    const refreshToken = localStorage.getItem('refreshToken')
    await Store.dispatch('user/refreshToken', refreshToken)
  }

  const tokenKey = window.localStorage.getItem('accessToken')
  if (tokenKey) {
    config.headers.Authorization = 'Bearer ' + tokenKey
  }
  return config
})

instance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response)
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      router.push({name: 'Login'})
    }
    return Promise.reject(error)
  },
)

export const http = instance