










import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class RippleLoader extends Vue {
  @Prop() public msg!: string;
}
