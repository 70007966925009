export class Devis {
  constructor(
    public id?: string,
    public annee_scolaire?: string,
    public date?: Date,
    public niveau?: string,
    public tarif?: number,
    public tarif_p1?: number,
    public tarif_p2?: number,
    public type?: string,
    public id_etab?: string,
  ) { }
}
