import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Store from '@/stores/store'

import Login from '../views/Login.vue'
import Accueil from '../views/Accueil.vue'
import Agenda from '../views/Agenda.vue'
import Recherche from '../views/Recherche.vue'
import Livraison from '../views/Livraison-details.vue'
import Revente from '../views/Revente-details.vue'
import Admin from '../views/Admin.vue'
import Devis from '../views/Devis-classe.vue'
import Documents from "@/views/Documents.vue";
import ResetPasswordEmail from '@/views/ResetPasswordEmail.vue';
import ResetPassword from '@/views/ResetPassword.vue';


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/reset-password',
    name: 'ResetPasswordEmail',
    component: ResetPasswordEmail
  },
  {
    path: '/reset-password/:key',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/',
    name: 'Accueil',
    meta: { requiresAuth: true },
    component: Accueil
  },
  {
    path: '/documents',
    name: 'Documents',
    meta: { requiresAuth: true },
    component: Documents
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: { requiresAuth: true, requiresAdmin: true },
    component: Admin
  },
  {
    path: '/agenda',
    name: 'Agenda',
    meta: { requiresAuth: true },
    component: Agenda
  },
  {
    path: '/recherche',
    name: 'Recherche',
    meta: { requiresAuth: true },
    component: Recherche
  },
  {
    path: '/Livraison-details',
    name: 'Livraison-details',
    meta: { requiresAuth: true },
    component: Livraison
  },
  {
    path: '/Revente-details',
    name: 'Revente-details',
    meta: { requiresAuth: true },
    component: Revente
  },
  {
    path: '/Devis-classe',
    name: 'Devis-classe',
    meta: { requiresAuth: true },
    component: Devis
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin)
  const isAuthenticated = Store.getters['user/isAuthenticated']
  const role = Store.getters['user/role']

  if (requiresAuth && !isAuthenticated) {
    next('/login')
  } else if (isAuthenticated && requiresAdmin && role === 'USER') {
    next('/')
  } else {
    next()
  }
})

export default router
