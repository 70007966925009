import { UserState } from './types'
import { RootState } from '@/stores/types'
import { GetterTree } from 'vuex'

export const getters: GetterTree<UserState, RootState> = {

  isAuthenticated(state) {
    if (localStorage.getItem('username')) {
      state.username = localStorage.getItem('username')!
    }
    return state.username
  },
  username(state): string | null {
    if (!state.username) {
      localStorage.setItem('username', JSON.stringify(state.username))
      return state.username
    }
    return localStorage.getItem('username')
  },
  role(state): string | null {
    if (state.role) {
      localStorage.setItem('role', state.role)
      return state.role
    }
    return localStorage.getItem('role')
  },
  token(state): string | null {
    if (!state.accessToken) {
      localStorage.setItem('accessToken', JSON.stringify(state.accessToken))
      return state.accessToken
    }
    return localStorage.getItem('accessToken')
  },
  searchRequest(state): {} | null {
    if (!state.searchRequest) {
      localStorage.setItem('searchRequest', JSON.stringify(state.searchRequest))
      return state.searchRequest
    }
    return JSON.parse(localStorage.getItem('searchRequest')!)
  }
}
