





















































































































































































import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Etablissement } from "@/models/Etablissement";
import AdminPanel from "@/components/AdminPanel.vue";
import AdresseEtablissement from "@/components/AdresseEtablissement.vue";
import Bars from "@/components/charts/Bars.vue";
import Doughnut from "@/components/charts/Doughnut.vue";
import LineChart from "@/components/charts/Line.vue";
import RechercheEtablissements from "@/components/RechercheEtablissements.vue";
import etablissementApi from "@/api/etablissement.api";
import moment from "moment";
import RippleLoader from "@/components/RippleLoader.vue";

@Component({
  components: {
    RippleLoader,
    AdminPanel,
    AdresseEtablissement,
    Bars,
    LineChart,
    Doughnut,
    RechercheEtablissements,
  },
})
export default class Accueil extends Vue {
  @Action("getStatsChronorelaisParEtablissement", {
    namespace: "etablissement",
  })
  public getStatsChronorelaisParEtablissement!: Function;
  @Action("getDistribStatsParEtablissement", { namespace: "etablissement" })
  public getDistribStatsParEtablissement!: Function;
  @Action("getCommandesParJourParNiveaux", { namespace: "etablissement" })
  public getCommandesParJourParNiveaux!: Function;
  @Action("getNiveaux", { namespace: "etablissement" })
  public getNiveaux!: Function;

  @Getter("etablissement", { namespace: "etablissement" })
  public etablissement!: Etablissement;
  @Getter("niveaux", { namespace: "etablissement" }) public niveaux!: string[];
  @Getter("role", { namespace: "user" }) public role!: string;
  @Getter("username", { namespace: "user" }) public username!: string;

  public tab = null;
  public fill = true;
  public padding = 8;
  public colors = [
    "#B7B949",
    "#B51553",
    "#858472",
    "#FFCC00",
    "#EE8800",
    "#EE6600",
    "#DC0000",
    "#ABCC01",
    "#019934",
    "#0099BB",
    "#343399",
    "#883388",
  ];

  // Commandes par jour
  public lineTitle = "";
  public lineLabels: string[] = [];
  public lineData: number[] = [];
  public lineLoading = true;

  // Commandes par niveau
  public barsLabels: string[] = [];
  public barsDataset: {}[] = [];
  public barLoading = true;

  // Répartition par niveau
  public doughnutLabels: string[] = [];
  public doughnutColors: string[] = [];
  public doughnutData: number[] = [];
  public levelDoughnutLoading = true;

  // Livraison (doughnut)
  public doughnutDistribLabels: string[] = ["Livrés", "Partiel", "Non livrés"];
  public doughnutDistribColors: string[] = ["#4caf50 ", "#fb8c00 ", "#ff5252 "];
  public doughnutDistribData: number[] = [];
  public distribLoading = true;

  // Livraison (tableau)
  public tableDistribues = "";
  public tableDistribuesPourcent = "";
  public tablePartDistribues = "";
  public tablePartDistribuesPourcent = "";
  public tableNonDistribues = "";
  public tableNonDistribuesPourcent = "";
  public tableChrono = "0";
  public tableChronoPourcent = "";
  public tableLivraisonDomicile = "0";
  public tableLivraisonDomicilePourcent = "";
  public tableLivraisonEtablissement = "0";
  public tableLivraisonEtablissementPourcent = "";
  public livraisonLoading = true;

  get groupementOrId() {
    let commandesRequest: string;

    if (this.etablissement.groupement) {
      commandesRequest = this.etablissement.groupement;
    } else {
      commandesRequest = "sansGroupement_" + this.etablissement.id;
    }
    return commandesRequest;
  }

  created(): void {
    // Linechart
    this.lineTitle = "Commandes par jour";
      etablissementApi
        .getCommandesParJour(this.groupementOrId)
        .then((responseCommandesParJour: any) => {
          Object.keys(responseCommandesParJour.data).forEach(
            (semaine: string) => {
              this.lineData.push(
                responseCommandesParJour.data[semaine].nbrCommande
              );
              this.lineLabels.push(
                this.formatDate(responseCommandesParJour.data[semaine].firstDay)
              );
            }
          );
        }).finally(() => this.lineLoading = false);

      // Bars
      this.getNiveaux(this.groupementOrId).then(() => {
        const listeDates: string[] = [];
        let listeNiveaux: string[] = [];
        const barsData: {}[] = [];
        let colorsCount = 0;

        etablissementApi
          .getCommandesParJourParNiveaux(this.groupementOrId)
          .then((responseCommandesParJourParNiveaux: any) => {
            // On récupère la liste des classes dispos dans les datas
            listeNiveaux = Object.keys(responseCommandesParJourParNiveaux.data);
            this.doughnutLabels = listeNiveaux;

            // On déduit le delta des semaines dispo dans les datas
            let lowerWeek: number;
            let higherWeek: number;
            listeNiveaux.forEach((niveau: string) => {
              const weeks: number[] = Object.keys(
                responseCommandesParJourParNiveaux.data[niveau]
              ).map(Number);
              if (!lowerWeek) lowerWeek = Math.min.apply(null, weeks);
              else if (lowerWeek && lowerWeek > Math.min.apply(null, weeks))
                lowerWeek = Math.min.apply(null, weeks);
              if (!higherWeek) higherWeek = Math.max.apply(null, weeks);
              else if (higherWeek && higherWeek < Math.max.apply(null, weeks))
                higherWeek = Math.max.apply(null, weeks);
              for (let index = lowerWeek!; index <= higherWeek!; index++) {
                if (
                  responseCommandesParJourParNiveaux.data[niveau][index] !=
                    undefined &&
                  !listeDates.includes(
                    new Date(
                      responseCommandesParJourParNiveaux.data[niveau][
                        index
                      ].firstDay
                    ).toLocaleDateString("fr-FR", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  )
                )
                  listeDates.push(
                    new Date(
                      responseCommandesParJourParNiveaux.data[niveau][
                        index
                      ].firstDay
                    ).toLocaleDateString("fr-FR", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  );
              }
            });

            // ordonner les dates
            listeDates.sort((a: string, b: string) => {
              moment.locale("fr");
              const dateA = moment(a, "LLL");
              const dateB = moment(b, "LLL");
              return dateA.isBefore(dateB) ? -1 : 1;
            });

            // On traite les datas dans l'ordre des semaines
            // S'il n'y a pas de data pour une classe sur une semaine données,
            // On insère des données vides pour éviter les trous de dates dans le graph
            listeNiveaux.forEach((niveau: string) => {
              if (responseCommandesParJourParNiveaux.data[niveau]) {
                const weeks: string[] = Object.keys(
                  responseCommandesParJourParNiveaux.data[niveau]
                ); // On récupère les semaines dispo dans chaque classe
                const classData: number[] = [];
                let cumulCommandes = 0; // Utilisé pour le doughnut

                for (let index = lowerWeek!; index <= higherWeek!; index++) {
                  if (!weeks.includes(index.toString())) {
                    classData.push(0);
                  } else {
                    classData.push(
                      responseCommandesParJourParNiveaux.data[niveau][index]
                        .nbrCommande
                    );
                    cumulCommandes +=
                      responseCommandesParJourParNiveaux.data[niveau][index]
                        .nbrCommande;
                  }
                }
                barsData.push({
                  label: niveau,
                  data: classData,
                  backgroundColor: this.colors[colorsCount],
                });
                this.doughnutData.push(cumulCommandes); // Utilisé pour le doughnut
                this.doughnutColors.push(this.colors[colorsCount]); // Utilisé pour le doughnut
              }
              if (colorsCount === this.colors.length - 1) {
                colorsCount = 0;
              } else {
                colorsCount++;
              }
            });

            this.barsLabels = listeDates; // Utilisé pour les Bars
            this.barsDataset = barsData; // Utilisé pour les Bars
          })
          .finally(() => {
            this.barLoading = false;
          });
      });

      // Array
      this.getDistribStatsParEtablissement(this.groupementOrId).then(
        (responseStats: any) => {
          responseStats.data.forEach(
            (stat: { type: string; nombre: string; proportion: string }) => {
              switch (stat.type) {
                case "Elèves ditribués":
                  this.tableDistribues = stat.nombre;
                  this.tableDistribuesPourcent = stat.proportion;
                  this.doughnutDistribData.push(parseInt(stat.nombre));
                  break;
                case "Elèves partiellement ditribués":
                  this.tablePartDistribues = stat.nombre;
                  this.tablePartDistribuesPourcent = stat.proportion;
                  this.doughnutDistribData.push(parseInt(stat.nombre));
                  break;
                case "Elèves non ditribués":
                  this.tableNonDistribues = stat.nombre;
                  this.tableNonDistribuesPourcent = stat.proportion;
                  this.doughnutDistribData.push(parseInt(stat.nombre));
                  break;
              }
            }
          );
        }
      ).finally(() => this.levelDoughnutLoading = false)
      this.getStatsChronorelaisParEtablissement(this.groupementOrId).then(
        (responseChrono: any) => {
          responseChrono.data.forEach(
            (element: { nombre: number; proportion: number; type: string }) => {
              if (element.type === "1") {
                this.tableLivraisonEtablissement = element.nombre.toString();
                this.tableLivraisonEtablissementPourcent = element.proportion.toString();
              }
              if (element.type === "2") {
                this.tableLivraisonDomicile = element.nombre.toString();
                this.tableLivraisonDomicilePourcent = element.proportion.toString();
              }
              if (element.type === "3") {
                this.tableChrono = element.nombre.toString();
                this.tableChronoPourcent = element.proportion.toString();
              }
            }
          );
        }
      ).finally(() => this.distribLoading = false)
  }

  // Formate la date envoyée par les WS pour la rendre lisible
  public formatDate(date: string): string {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const readableDate = new Date(date).toLocaleDateString("fr-FR", options);
    return readableDate;
  }
}
