













































































































import {Vue, Component, Watch} from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { Etablissement } from '@/models/Etablissement'
import Alert from '@/components/Alert.vue'
import elevesApi from '@/api/eleves.api'
import moment from 'moment';

@Component({
  components: {
    Alert
  }
})
export default class RechercheEtablissements extends Vue {
  @Action('filtreCommande', { namespace: 'commande' }) public filtreCommande!: Function
  @Action('saveSearchRequest', { namespace: 'user' }) public saveSearchRequest!: Function

  @Getter('etablissement', { namespace: 'etablissement' }) public etablissement!: Etablissement
  @Getter('searchRequest', { namespace: 'user' }) public searchRequest!: any

  public loadingNom = false
  public loadingPrenom = false
  public loadingAnnees = false

  public $refs: any = {}
  public nom = ''
  public noms = []
  public prenom = ''
  public prenoms = []
  public niveau = ''
  public niveaux = []
  public annee = ''
  public anneesForSelect = []
  public anneesScolaires: {display: string; value: string}[] = []

  public statutLivraison = ''
  public statutRevente = ''
  public statutsLivraison = [
    { text: 'Livrées', value: '1' },
    { text: 'Partiellement livrées', value: '0' },
    { text: 'Non livrées', value: '-1' },
  ]
  public statutsRevente = [
    { text: 'Revendues', value: '1' },
    { text: 'Partiellement revendues', value: '0' },
    { text: 'Non revendues', value: '-1' },
  ]


  @Watch('statutLivraison')
  onStatutLivraisonChanged(value: string) {
    if (value != '') {
      this.statutRevente = ''
      this.statutLivraison = value
    }
  }

  @Watch('statutRevente')
  onStatutReventeChanged(value: string) {
    if (value != '') {
      this.statutLivraison = ''
      this.statutRevente = value
    }
  }

  get groupementOrId() {
    let commandesRequest: string

    if (this.etablissement.groupement) {
      commandesRequest = this.etablissement.groupement
    } else {
      commandesRequest = 'sansGroupement_'+this.etablissement.id
    }
    return commandesRequest
  }

  mounted() {        
    this.loadingNom = true

    elevesApi.getElevesNoms(this.groupementOrId).then((responseNoms: any) => {
      this.noms = responseNoms.data
    }).finally(() => {
      this.loadingNom = false
    })

    this.loadingPrenom = true
    elevesApi.getElevesPrenoms(this.groupementOrId).then((responsePrenoms: any) => {
      this.prenoms = responsePrenoms.data
    }).finally(() => {
      this.loadingPrenom = false
    })

    this.loadingAnnees = true
    elevesApi.getEtablissementAnnees(this.groupementOrId).then((responseAnnees: any) => {      
      responseAnnees.data.forEach((annee: string) => {
        this.anneesScolaires.push({
          display: "20" + annee.substring(0,2) + "-" + "20" + annee.substring(2,4),
          value: annee
        })        
      }) 
    }).finally(() => {
      elevesApi.getEtablissementNiveaux(this.groupementOrId).then((responseNiveaux: any) => {
        this.niveaux = responseNiveaux.data
      }).finally(() => {
        this.loadingAnnees = false
      })
    })
    this.annee = this.currentYear().value;
    this.anneesScolaires.push(this.currentYear());

    // 56500 - Récupération des critères de la recherche précédente
    // Exception sur l'année - on reste sur l'année par défaut
    if (this.searchRequest) {
      this.niveau = this.searchRequest.niveau
      this.prenom = this.searchRequest.prenom
      this.nom = this.searchRequest.nom
      this.annee = this.searchRequest.annee
      this.statutLivraison = this.searchRequest.statutLivraison
      this.statutRevente = this.searchRequest.statutRevente
    }
  }

  public customFilter(item: string, queryText: string, itemText: string): boolean {
    if (itemText.toLowerCase().startsWith(queryText.toLowerCase())) return true
    else return false
  }

  public startSearch(): void {
   
    const request = {
      annee: this.annee,
      groupement: this.groupementOrId,
      niveau: this.niveau,
      prenom: this.prenom,
      nom: this.nom,
      statutLivraison: this.statutLivraison,
      statutRevente: this.statutRevente,
    }

    this.saveSearchRequest(request)

    this.$emit('startLoading')
    this.filtreCommande(request).catch(() => {
       this.$refs.snackbar.open('une erreur est survenue lors de la recherche des commandes. Veuillez réessayer.')
    }).finally(() => {
      this.$emit('endLoading')
      if (this.$route.name != 'Recherche')
        this.$router.push({name: 'Recherche' })
      })
    
  }

  public currentYear() {    
    const dateStart = moment(new Date());
    dateStart.day("1");
    dateStart.month("5");
    dateStart.year(new Date().getFullYear())
    const nowDate = moment(Date.now());  
    let prefix;
    let suffix;  
    const year = new Date().getFullYear().toString();
    const annee = {
      display: "",
      value: ""
    }; 
    if (nowDate.diff(dateStart) >= 0) {      
      prefix = (parseInt(year.substring(2,4))).toString();
      suffix = (parseInt(year.substring(2,4))+1).toString();
      } else {         
      prefix = (parseInt(year.substring(2,4))-1).toString();
      suffix = (parseInt(year.substring(2,4))).toString();      
    }   
    annee.display = ("20" + prefix + "-" + "20" + suffix);
    annee.value = prefix + suffix;            
    return annee;
  }
}
