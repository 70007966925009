export class Etablissement {
  constructor(
    public id?: number,
    public username?: string,
    public adresse?: string,
    public cp?: string,
    public email?: string,
    public groupement?: string,
    public nom?: string,
    public pays?: string,
    public unite?: string,
    public ville?: string,
    public nbrDelivered?: number,
    public nbrOnDelivery?: number,
    public statut?: number,
    public livraisonStatut?: number,
    public reventeStatut?: number,
    public livraisonNbrDelivered?: number,
    public livraisonNbrOnDelivery?: number,
    public reventeNbrDelivered?: number,
    public reventeNbrOnDelivery?: number,
  ) { }
}
