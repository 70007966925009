import { http } from './http'

export default {
  uploadDocument(doc): Promise<{}> {
    return http.post('/documents/save', doc, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  getDocuments(data): Promise<{}> {
    const params = new URLSearchParams();
    params.append("etablissements", data.etablissements);
    return http.post('/documents/', params);
  },
  deleteDocument(data): Promise<{}> {
    return http.delete('/documents/' + data.id);
  },
  editDocument(data): Promise<{}> {
    const params = new URLSearchParams();
    params.append("etablissement", data.etablissements);
    params.append("nom", data.nom);
    return http.put('/documents/' + data.id, params);
  },
  seeDocument(data): Promise<{}> {
    return http.put('/documents/' + data.id + '/' + data.etablissement);
  },
  notifsDocuments(data): Promise<{}> {
    return http.get('/documents/' + data.id + '/notseen');
  }

}
