import { ChargeUniteState } from './types'
import { RootState } from '@/stores/types'
import { GetterTree } from 'vuex'

export const getters: GetterTree<ChargeUniteState, RootState> = {
  unites(state): string [] | null{
    if (!state.unites) {
      localStorage.setItem('unites', JSON.stringify(state.unites))
      return state.unites
    }
    return JSON.parse(localStorage.getItem('unites')!)
  },
}
