






import { Vue, Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'

@Component({
  components: {}
})
export default class AdminPanel extends Vue {
  @Action('clearSearchRequest', { namespace: 'user' }) public clearSearchRequest!: Function
  @Action('clearNiveaux', { namespace: 'etablissement' }) public clearNiveaux!: Function

  public goToAdmin() {
    this.clearNiveaux()
    this.clearSearchRequest()
    this.$router.push({ name: 'Admin'})
  }

}
