import {actions} from './actions'
import {Module} from 'vuex'
import {RootState} from '@/stores/types'
import {DocumentState} from './types'

const namespaced = true

export const document: Module<DocumentState, RootState> = {
  namespaced,
  actions,
}
