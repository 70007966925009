










import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class FullScreenLoader extends Vue {

  @Prop() public msg!: string

}
