

















































import { Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { Identifiants } from '@/models/Identifiants'
import Alert from '@/components/Alert.vue'
import FormMixin from '@/mixins/FormMixin'

@Component({
  components: {
    Alert
  }
})

export default class Login extends FormMixin {
  @Action('login', { namespace: 'user' }) public login!: Function
  @Action('logout', { namespace: 'user' }) public logout!: Function
  @Action('getRoles', { namespace: 'user' }) public getRoles!: Function
  @Action('getEtablissement', { namespace: 'etablissement' }) public getEtablissement!: Function
  @Action('getGroupement', { namespace: 'etablissement' }) public getGroupement!: Function
  @Action('getEtablissementParGroupement', { namespace: 'etablissement' }) public getEtablissementParGroupement!: Function
  @Action('setEtablissement', { namespace: 'etablissement' }) public setEtablissement!: Function
  @Action('getUnites', { namespace: 'chargeUnite' }) public getUnites!: Function
  @Action('rechercheEtablissement', { namespace: 'admin'}) public rechercheEtablissement!: Function

  @Getter('isAuthenticated', { namespace: 'user' }) public isAuthenticated?: boolean
  @Getter('role', { namespace: 'user' }) public role?: string
  @Getter('etablissement', { namespace: 'etablissement' }) public etablissement?: string | null

  public username = ''
  public password = ''
  public loginLoading = false
  public errorMessage = ''

  public $refs: any = {
    loginForm: HTMLFormElement
  }

  created() {
    this.logout()
  }

  public validate(): void {

    const user: Identifiants = { password: this.password, username: this.username }
    let error = false

    if (this.$refs.loginForm.validate()) {
      this.loginLoading = true
      this.login(user).catch(() => {
        error = true
        this.errorMessage = 'L\'identifiant ou le mot de passe n\'est pas valide'
      }).finally(() => {
        if (!error) {
          this.getRoles().catch(() => {
            error = true
            this.errorMessage = 'Une erreur s\'est produite pendant l\'identification. Veuillez réessayer.'
          }).finally(() => {
            if (!error) {
              if (this.role === 'ADMIN' && !error) 
              {     
                this.$router.push({name:'Admin'})
              }
              else if(this.role === 'CHARGE_UNITE')
              {
                  this.getUnites(this.username).catch(() => {
                    error = true
                    this.errorMessage = 'Une erreur s\'est produite pendant la récupération des données. Veuillez réessayer.'
                  }).finally(() =>{
                    this.loginLoading = false
                    if (error && this.errorMessage !== '') {
                      this.$refs.snackbar.open(this.errorMessage)
                    } else {
                      this.$router.push({name:'Admin'})
                    }
                  })                                  
              }
              else {
                this.getEtablissement(this.username).catch(() => {
                  error = true
                  this.errorMessage = 'Une erreur s\'est produite pendant la récupération des données. Veuillez réessayer.'
                }).finally(() => {
                  this.loginLoading = false
                  if (!this.etablissement) {
                    error = true
                    this.errorMessage = 'Votre compte n\'est pas associé à un établissement scolaire.'
                  }
                  if (error && this.errorMessage !== '') {
                    this.$refs.snackbar.open(this.errorMessage)
                  } else {
                    this.$router.push({name:'Accueil'})
                  }
                })
              }
            } else {
              this.loginLoading = false
              this.$refs.snackbar.open(this.errorMessage)
            }
          })
        } else {
          this.loginLoading = false
          this.$refs.snackbar.open(this.errorMessage)
        }
      })
    }
  }

}

