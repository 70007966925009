






















import FormMixin from '@/mixins/FormMixin';
import loginApi from '@/api/login.api';
import { Component } from 'vue-property-decorator'
import Alert from '@/components/Alert.vue'
import { Getter } from 'vuex-class';

@Component({
  components: {
    Alert
  }
})

export default class ResetPasswordEmail extends FormMixin {
  @Getter('isAuthenticated', { namespace: 'user' }) public isAuthenticated?: string;

  public email = '';
  public firstName = '';
  public lastName = '';
  public sendLoading = false;
  public success = false;
  public errorMessage = '';

  public $refs: any = {
    resetPasswordEmailForm: HTMLFormElement
  }

  public validate(): void {
    if (this.$refs.resetPasswordEmailForm.validate()) {
      this.sendLoading = true;

      loginApi.createResetPasswordKey(this.email, this.firstName, this.lastName).then((response) => {
        this.sendLoading = false;
        this.success = true;
        this.$refs.snackbar.open('Un email avec un lien de restauration a été envoyé.', 'success');
      }).catch(({ response }) => {
        this.sendLoading = false;

        switch (response.status) {
          case 404:
            this.errorMessage = 'Cette adresse email n\'est pas enregistrée.'
            break;

          case 429:
            this.errorMessage = 'Une demande pour cette adresse email à récemment  été envoyer. Merci de réessayer  plus tard.'
            break;

          default:
            this.errorMessage = 'Une erreur est survenue.';
            break;
        }

        if (this.errorMessage !== '') {
          this.$refs.snackbar.open(this.errorMessage);
        }
      });
    }
  }
}

