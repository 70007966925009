

















































import { Vue, Component} from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { Devis } from '@/models/Devis'
import { Etablissement } from '@/models/Etablissement'

import AdminPanel from '@/components/AdminPanel.vue'
import AdresseEtablissement from "@/components/AdresseEtablissement.vue"
import RechercheEtablissements from "@/components/RechercheEtablissements.vue"

@Component({
  components: {
    AdminPanel,
    AdresseEtablissement,
    RechercheEtablissements
  }
})

export default class DevisClasse extends Vue{
  @Getter('devisByNiveau', { namespace: 'commande' }) public devisByNiveau!: Devis
  @Getter('etablissement', { namespace: 'etablissement' }) public etablissement!: Etablissement
  @Getter('role', { namespace: 'user' }) public role!: string
  
}
