import { ChargeUniteState } from './types'
import { ActionTree } from 'vuex'
import { RootState } from '@/stores/types'
import ChargeUniteApi from '@/api/chargeUnite.api'


export const actions: ActionTree<ChargeUniteState, RootState> = {
  resetPassword({ commit },ids: {password: string; username: string}): Promise<void|{}> {
    return ChargeUniteApi.resetPassword(ids)
  },
  getUnites({ commit }, login: string): Promise<void|{}> {
    return ChargeUniteApi.getUnites(login).then((response) => {  
        commit('GET_UNITES', response)  
    })
  },
}
