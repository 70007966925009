import { CommandeState } from './types'
import { ActionTree } from 'vuex'
import { RootState } from '@/stores/types'
import CommandeApi from '@/api/commande.api'
import DevisApi from '@/api/devis.api'

import { Commande } from '@/models/Commande'

export const actions: ActionTree<CommandeState, RootState> = {
  filtreCommande({ commit }, request: {annee: string; groupement: string; niveau: string; prenom: string; nom: string; statutLivraison: string; statutRevente: string}): Promise<void|{}> {
    return CommandeApi.filtreCommande(request.annee, request.groupement, request.niveau, request.prenom, request.nom, request.statutLivraison, request.statutRevente).then((activitesResponse) => {
      commit('FILTRE_COMMANDES', activitesResponse)
    })
  },
  exportXLS({ commit }, request: {annee: string; groupement: string; niveau: string; prenom: string; nom: string; statutLivraison: string; statutRevente: string}): Promise<void|{}> {
    return CommandeApi.xls(request.annee, request.groupement, request.niveau, request.prenom, request.nom, request.statutLivraison, request.statutRevente).then((activitesResponse) => {
      return activitesResponse
    })
  },
  setSelectedCommande({ commit }, commande: Commande): void {
    commit('SET_SELECTED_COMMANDE', commande)
  },
  livres({commit},commandeId: string): Promise<void|{}>{
    return CommandeApi.livresParIdCommande(commandeId).then((activitesResponse)=>{
      commit('LIVRES',activitesResponse)
    })
  },
  devisByNiveau({commit},request: {etablissementId: string; niveau: string}): Promise<void|{}>{
    return DevisApi.getDevis(request.etablissementId,request.niveau).then((activitesResponse)=>{
      commit('DEVIS_BY_NIVEAU',activitesResponse)
    })
  }
}
