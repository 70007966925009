

























































import { Vue, Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Activite } from "@/models/Activite";
import { Etablissement } from "@/models/Etablissement";
import AdminPanel from "@/components/AdminPanel.vue";
import AdresseEtablissement from "@/components/AdresseEtablissement.vue";
import RechercheEtablissements from "@/components/RechercheEtablissements.vue";
import activitesApi from "@/api/activites.api";

@Component({
    components: {
        AdminPanel,
        AdresseEtablissement,
        RechercheEtablissements,
    },
})
export default class Agenda extends Vue {
    @Getter("role", { namespace: "user" }) public role!: string;
    public activites = [];
    @Getter("etablissement", { namespace: "etablissement" })
    public etablissement!: Etablissement;
    public items = [];
    public headers = [
        { text: "Date", value: "date", sortable: false },
        { text: "Opération", value: "libelle", sortable: false },
        { text: "Début", value: "dateDebut", sortable: false },
        { text: "Fin", value: "dateFin", sortable: false },
        { text: "Classe", value: "classe", sortable: false },
    ];

    public loadingMsg = "Chargement...";

    get isAdmin(): boolean {
        return this.role === "ADMIN";
    }

    get groupementOrId() {
        return this.etablissement.groupement
            ? (this.etablissement.groupement)
            : ("sansGroupement_" + this.etablissement.id)
    }

    created() {
        //56022 - Appel direct aux WS pour éviter les erreurs sur le composant v-data-table
        activitesApi
            .getActivitiesAvecGroupementIdByAnnee(this.groupementOrId)
            .then((response: any) => { 
                if (response.status === 200) {
                    this.activites = response.data
                    this.setColorsToActivites()
                } else if (response.status === 204) {
                    this.loadingMsg = "Aucune donnée disponible"
                }
            })
            .catch((error) => {
                this.loadingMsg =
                    "Une erreur est survenue: " + JSON.stringify(error.message)
            });
            console.log(this.activites);
            
    }

    public setColorsToActivites(): void {
        if (this.activites && this.activites.length > 0) {
            this.activites.forEach((activite: Activite) => {
                let eventColor = new String();
                switch (activite.type) {
                    case "RAMAS":
                        eventColor = "blue";
                        break;
                    case "DISTRIB":
                        eventColor = "red";
                        break;
                    case "PERMDIST":
                        eventColor = "deep-purple";
                        break;
                    case "PERMREV":
                        eventColor = "cyan";
                        break;
                    case "DISTPACKALL":
                        eventColor = "green";
                        break;
                    case "PERREV":
                        eventColor = "orange";
                        break;
                    case "DISTRATT":
                        eventColor = "yellow";
                        break;
                }
                Vue.set(activite, "color", eventColor);
            });
        }
    }

    public formatDate(date: string, detail = false): string {
        let options: {};
        if (detail) {
            options = {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
            };
        } else {
            options = { year: "numeric", month: "long", day: "numeric" };
        }
        const readableDate = new Date(date).toLocaleDateString(
            "fr-FR",
            options
        );
        return readableDate;
    }

    public prettyTime(time: string) {
        const date = new Date(time);
        return date.toLocaleString('fr-FR', { 
            hour: "2-digit",
            minute: "2-digit",
            timeZone: 'UTC'
        });
    }
}
