import { http } from './http'

export default {

  getEtablissements(): Promise<void> {
    return http.get('/etablissement/admin/etab')
  },
  getEtablissementsParUnite(unite: string): Promise<void> {
    return http.get('/etablissement/testunite/' + unite)
  },
  getCommandesParJour(id: string): Promise<void> {
    return http.get('/etablissement/' + id + '/count_commande/')
  },
  getCommandesParJourParNiveaux(groupementId: string): Promise<void> {
    return http.get('/etablissement/' + groupementId + '/count_commande/niveau')
  },
  getEtablissementsParGroupement(groupement: string): Promise<void> {
    return http.get('/etablissement/etab-groupement/' + groupement)
  },
  getGroupement(login: string): Promise<void> {
    const params = new URLSearchParams();
    params.append("login", login);
    return http.get('/etablissement/groupement', { params }) // TODO
  },
  getStatsChronorelaisParEtablissement(groupementId: string): Promise<void> {
    return http.get('/etablissement/stat_mode_livraison/' + groupementId)
  },
  getDistribStatsParEtablissement(groupementId: string): Promise<void> {
    return http.get('/etablissement/stat_distribution/' + groupementId)
  },
  updateMotDePasse(passwords: {newPassword: string; oldPassword: string}): Promise<{}> {
    return http.post('/etablissement/update-password', passwords)
  },
  getEtablissement(login: string): Promise<void> {
    return http.get('/etablissement/' + login)
  }
}
