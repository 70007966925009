





















































































































































































































































































































































































import { Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import { Etablissement } from "@/models/Etablissement";
import AdminApi from "@/api/admin.api";
import AdminPanel from "@/components/AdminPanel.vue";
import Alert from "@/components/Alert.vue";
import FormMixin from "@/mixins/FormMixin";
import Loader from "@/components/FullScreenLoader.vue";
import RechercheEtablissementsAdmin from "@/components/RechercheEtablissementsAdmin.vue";

@Component({
  components: {
    AdminPanel,
    Alert,
    Loader,
    RechercheEtablissementsAdmin,
  },
})
export default class Admin extends FormMixin {
  @Action("getEtablissementParGroupement", { namespace: "etablissement" })
  public getEtablissementParGroupement!: Function;
  @Action("getEtablissements", { namespace: "etablissement" })
  public getEtablissements!: Function;
  @Action("updateGroupement", { namespace: "admin" })
  public updateGroupement!: Function;
  @Action("resetPassword", { namespace: "chargeUnite" })
  public resetPassword!: Function;
  @Action("setEtablissement", { namespace: "etablissement" })
  public setEtablissement!: Function;
  @Action("saveSearchRequestAdmin", { namespace: "admin" })
  public saveSearchRequestAdmin!: Function;

  @Getter("etablissements", { namespace: "etablissement" })
  public etablissements!: Etablissement[];
  @Getter("username", { namespace: "user" }) public username!: string;
  @Getter("role", { namespace: "user" }) public role?: string;
  @Getter("unites", { namespace: "chargeUnite" }) public unite?: string[];
  @Getter("searchRequestAdmin", { namespace: "admin" })
  public searchRequestAdmin?: string;

  public listSize = 0;

  public groupementsAdmin: string[] = [];
  public searchResults: Etablissement[] = [];

  public currentTab = 0;
  public pageLoading = false;
  public $refs: any = {};
  public selectedEtablissement: Etablissement = new Etablissement();

  public dialogLoading = false;
  public groupementsLoading = false;

  public changementMdpValide = false;
  public openChangementMdp = false;
  public newMdp = "";
  public confirmMdp = "";

  public changementGroupementValide = false;
  public openChangementGroupement = false;
  public newGroupement = "";

  created() {
    // 0060058: ARBS - TMA - Site Etab - Evolution - Affichage prioritaire des opérations de Livraison et de Revente selon une date
    const date = new Date();

    // 16 mai
    const startReventeDate = new Date(date.getFullYear(), 4, 16);
    // 14 aout
    const endReventeDate = new Date(date.getFullYear(), 7, 14);

    if (date >= startReventeDate && date <= endReventeDate) {
      // Selection des reventes par défaut.
      this.currentTab = 1;
    } else {
      // Selection des livraisons par défaut.
      this.currentTab = 0;
    }

    let request = {};
    if (this.searchRequestAdmin) {
      // 56500 - Si une recherche a été passée avant, on reprend ses critères
      request = this.searchRequestAdmin;
    } else {
      // Sinon, on lance une recherche à vide
      // Avec l'unité renseignée si l'utilisateur loggé est un chargé d'unité
      if (this.role === "CHARGE_UNITE") {
        request = {
          unites: this.unite,
        };
      }
    }
    this.recherchesEtablissements(request);
  }

  public recherchesEtablissements(requestSearch: any): void {
    this.saveSearchRequestAdmin(requestSearch);
    this.searchResults = [];
    this.pageLoading = true;
    AdminApi.rechercheEtablissement(requestSearch)
      .then((response: any) => {
        this.listSize = response.data.length;
        this.searchResults = response.data;
      })
      .catch(() => {
        this.$refs.snackbar.open(
          `Une erreur est survenue lors de la recherche d'établissement. Veuillez réessayer.`
        );
      })
      .finally(() => {
        this.pageLoading = false;
      });
  }

  public connexionEtab(etablissement: Etablissement): void {
    this.dialogLoading = true;
    let EtabToGo = new Etablissement();
    let groupement = "";
    if (etablissement.groupement == "") {
      groupement = "sansGroupement_" + etablissement.id;
    } else {
      groupement = etablissement.groupement!;
    }
    this.getEtablissementParGroupement(groupement)
      .catch(() => {
        this.$refs.snackbar.open(
          `Une erreur est survenue lors de la récupération des infos de l'établissement.`
        );
      })
      .finally(() => {
        this.etablissements.forEach((etab: Etablissement) => {
          if (etab.id === etablissement.id) {
            EtabToGo = etab;
          }
        });
        this.setEtablissement(EtabToGo);
        this.$router.push({ name: "Accueil" });
        this.dialogLoading = false;
      });
  }

  public changementMdp(etablissement: Etablissement): void {
    this.openChangementMdp = true;
    this.selectedEtablissement = etablissement;
  }

  public changeMdp(): void {
    this.dialogLoading = true;
    if (this.changementMdpValide) {
      let wsError = false;
      const ids = {
        password: this.newMdp,
        username: this.selectedEtablissement.email,
      };
      this.resetPassword(ids)
        .catch(() => {
          wsError = true;
          this.$refs.snackbar.open(
            `Une erreur est survenue. Veuillez réessayer.`
          );
        })
        .finally(() => {
          if (!wsError) {
            this.$refs.snackbar.open(
              `Changement de mot de passe effectué.`,
              "success"
            );
          }
          this.newMdp = "";
          this.confirmMdp = "";
          this.dialogLoading = false;
          this.openChangementMdp = false;
        });
    } else {
      this.dialogLoading = false;
    }
  }

  public changementGroupement(etablissement: Etablissement): void {
    this.openChangementGroupement = true;
    this.selectedEtablissement = etablissement;
    this.groupementsLoading = true;
    AdminApi.getGroupements()
      .then((response: any) => {
        this.groupementsAdmin = response.data;
      })
      .catch(() => {
        this.$refs.snackbar.open(
          `Une erreur est survenue lors de la récupération des groupements.`
        );
      })
      .finally(() => {
        this.groupementsLoading = false;
      });
  }

  public changeGroupement(): void {
    this.dialogLoading = true;
    if (this.changementGroupementValide) {
      let wsError = false;
      let request;
      const newGroup = this.newGroupement;
      if (newGroup == "") {
        request = {
          id: this.selectedEtablissement.id,
        };
      } else {
        request = {
          groupement: this.newGroupement,
          id: this.selectedEtablissement.id,
        };
      }
      this.updateGroupement(request)
        .catch(() => {
          wsError = true;
          this.$refs.snackbar.open(
            `Une erreur est survenue. Veuillez réessayer.`
          );
        })
        .finally(() => {
          this.dialogLoading = false;
          this.openChangementGroupement = false;
          if (!wsError) {
            this.$refs.snackbar.open(
              `Changement de groupement effectué.`,
              "success"
            );
            this.searchResults.forEach((listEl: Etablissement) => {
              if (listEl.id === this.selectedEtablissement.id)
                listEl.groupement = this.newGroupement;
            });
            this.newGroupement = "";
            this.selectedEtablissement = new Etablissement();
          }
        });
    } else {
      this.dialogLoading = false;
    }
  }

  public processOk(param: Etablissement, isLivraison: boolean): boolean {
    if (isLivraison) {
      if (
        (param.livraisonNbrDelivered == 0 &&
          param.livraisonNbrOnDelivery == 0) ||
        param.livraisonStatut === 1
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        (param.reventeNbrDelivered == 0 && param.reventeNbrOnDelivery == 0) ||
        param.reventeStatut === 1
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  public processEnCours(param: Etablissement, isLivraison: boolean): boolean {
    if (isLivraison) {
      if (param.livraisonStatut && param.livraisonStatut < 1) {
        return true;
      } else {
        return false;
      }
    } else {
      if (param.reventeStatut && param.reventeStatut < 1) {
        return true;
      } else {
        return false;
      }
    }
  }

  public processNok(param: Etablissement, isLivraison: boolean): boolean {
    if (isLivraison) {
      if (param.livraisonStatut === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (param.reventeStatut === 0) {
        return true;
      } else {
        return false;
      }
    }
  }
}
