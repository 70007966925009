import { EtablissementState } from './types'
import { RootState } from '@/stores/types'
import { GetterTree } from 'vuex'
import { Activite } from '@/models/Activite'
import { Etablissement } from '@/models/Etablissement'

export const getters: GetterTree<EtablissementState, RootState> = {
  groupement(state): string | null {
    if (!state.groupement) {
      localStorage.setItem('groupement', JSON.stringify(state.groupement))
      return state.groupement
    }
    return localStorage.getItem('groupement')
  },
  etablissement(state): Etablissement {
    if (!state.etablissement) {
      localStorage.setItem('etablissement', JSON.stringify(state.etablissement))
      return state.etablissement
    }
    return JSON.parse(localStorage.getItem('etablissement')!)
  },
  etablissements(state): Etablissement[] {
    if (!state.etablissements) {
      localStorage.setItem('etablissements', JSON.stringify(state.etablissements))
      return state.etablissements
    }
    return JSON.parse(localStorage.getItem('etablissements')!)
  },
  activites(state): Activite[] {
    if (!state.activites) {
      localStorage.setItem('activites', JSON.stringify(state.activites))
      return state.activites
    }
    return JSON.parse(localStorage.getItem('activites')!)
  },
  niveaux(state): string | null {
    if (!state.niveaux) {
      localStorage.setItem('niveaux', JSON.stringify(state.niveaux))
      return state.niveaux
    }
    return JSON.parse(localStorage.getItem('niveaux')!)
  }
}
