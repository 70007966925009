import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi'
    },
    theme: {
        themes: {
            light: {
                primary: '#034155',
                primarylight: '#034055',
                secondary: '#fb4d61',
                secondarylight: '#fb4d61',
                accent: '#67afb1',
                background: '#f5f5f5',
                white: '#fff',
                admin: '#5e77e6'
            }
        }
    }
});
