import { http } from './http'

export default {
  getStatus(): Promise<void> {
    return http.get('/admin/calcul_statut')
  },
  getGroupements(): Promise<void> {
    return http.get('/admin/get-groupements')
  },
  updateGroupement(updateRequest: {groupement: string; id: string}): Promise<{}> {
    const params = new URLSearchParams();
    if(updateRequest.groupement) params.append("groupement", updateRequest.groupement);
    params.append("id", updateRequest.id);
    return http.put('/admin/update_groupement', params)
  },
  rechercheEtablissement(rechercheRequest: {etablissementId: string; no_command: boolean; nom: string; type: string; undelivered_command: boolean; undelivered_resale: boolean; unites: string[]}): Promise<{}> {
    const params = new URLSearchParams();
    if(rechercheRequest)
    {
      if (rechercheRequest.etablissementId) params.append("etablissementId", rechercheRequest.etablissementId);
    if (rechercheRequest.no_command) params.append("no_command", rechercheRequest.no_command.toString());
    if (rechercheRequest.nom) params.append("nom", rechercheRequest.nom);
    if (rechercheRequest.undelivered_command) params.append("undelivered_command", rechercheRequest.undelivered_command.toString());
    if (rechercheRequest.undelivered_resale) params.append("undelivered_resale", rechercheRequest.undelivered_resale.toString());
    if (rechercheRequest.unites) params.append("unites", rechercheRequest.unites.toString());
    }
    
    return http.post('/admin/recherche_etablissement', null, { params })
  },
}
