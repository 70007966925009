




import { Component, Prop, Vue } from 'vue-property-decorator'
import { Chart } from 'chart.js'

@Component
export default class Doughnut extends Vue {
  @Prop() canvasId!: string
  @Prop() labels!: Array<string>
  @Prop() colors!: Array<string>
  @Prop() data!: Array<number>
  @Prop({
    default: () => {
      return Chart.defaults.doughnut
    }
  })

  mounted(): void {
    this.createChart({
      datasets: [
        {
          data: this.data,
          backgroundColor: this.colors
        }
      ],
      labels: this.labels
    })
  }

  createChart(chartData: object) {
    const canvas = document.getElementById(this.canvasId) as HTMLCanvasElement
    const options: object = {
      type: 'doughnut',
      data: chartData,
      options: {
        legend: {
            display: true,
            position: 'bottom'
        }
      }
    }
    new Chart(canvas, options)
  }
}
