import { MutationTree } from 'vuex'
import { Etablissement } from '@/models/Etablissement'
import { EtablissementState } from './types'

export const mutations: MutationTree<EtablissementState> = {
  GET_GROUPEMENT(state, response): void {
    state.groupement = response.data
    localStorage.setItem('groupement', JSON.stringify(response.data))
  },
  GET_ETABLISSEMENTS(state, response): void {
    state.etablissements = response.data
    localStorage.setItem('etablissements', JSON.stringify(response.data))
  },
  SET_ETABLISSEMENT(state, response): void {
    state.etablissement = response.data
    localStorage.setItem('etablissement', JSON.stringify(response.data))
  },
  SET_ETABLISSEMENT_FROM_ADMIN(state, etablissement: Etablissement): void {
    state.etablissement = etablissement
    localStorage.setItem('etablissement', JSON.stringify(etablissement))
  },
  GET_NIVEAUX(state, response): void {
    state.activites = response.data
    localStorage.setItem('niveaux', JSON.stringify(response.data))
  },
  CLEAR_NIVEAUX(state): void {
    localStorage.removeItem('niveaux')
    state.niveaux = []
  }
}
