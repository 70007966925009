import { Vue, Component } from 'vue-property-decorator'

@Component
export default class FormMixin extends Vue {
  public checkRequired(value: string): string | boolean {
    return !!value || `Le champ est requis`
  }

  public checkMail(value: string): string | boolean {
    const pattern = /^(([^<>()[].,;:s@"]+(.[^<>()[].,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || `Le format de l'email est invalide`
  }

  public checkPassword(value: string): any {
    const pattern = /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/
    const defined = value !== undefined && value !== ''
    return pattern.test(value) || !defined || `Mot de passe invalide : veuillez utiliser au moins 8 caractères dont une majuscule, une minuscule, un chiffre et un caractère spécial.`
  }

}
