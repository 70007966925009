import { CommandeState } from './types'
import { RootState } from '@/stores/types'
import { GetterTree } from 'vuex'

export const getters: GetterTree<CommandeState, RootState> = {
  commandes(state): string | null {
    if (!state.commandes) {
      localStorage.setItem('commandes', JSON.stringify(state.commandes))
      return state.commandes
    }
    return JSON.parse(localStorage.getItem('commandes')!)
  },
  selectedCommande(state): string | null {
    if (!state.selectedCommande) {
      localStorage.setItem('selectedCommande', JSON.stringify(state.selectedCommande))
      return state.selectedCommande
    }
    return JSON.parse(localStorage.getItem('selectedCommande')!)
  },
  livres(state): string | null{
    if (!state.livres)
    { 
      localStorage.setItem('livres',JSON.stringify(state.livres))
      return state.livres
    }
    return JSON.parse(localStorage.getItem('livres')!)
  },
  
  devisByNiveau(state): string | null{
    if (!state.devisByNiveau)
    { 
      localStorage.setItem('devisByNiveau',JSON.stringify(state.devisByNiveau))
      return state.devisByNiveau
    }
    return JSON.parse(localStorage.getItem('devisByNiveau')!)

  }
}
