import { MutationTree } from 'vuex'
import { CommandeState } from './types'

import { Commande } from '@/models/Commande'

export const mutations: MutationTree<CommandeState> = {
  FILTRE_COMMANDES(state, response): void {
    state.commandes = response.data
    localStorage.setItem('commandes', JSON.stringify(response.data))
  },
  SET_SELECTED_COMMANDE(state, commande: Commande): void {
    state.selectedCommande = commande
    localStorage.setItem('selectedCommande', JSON.stringify(commande))
  },
  LIVRES(state, response): void {
    state.livres = response.data
    localStorage.setItem('livres', JSON.stringify(response.data))
  },
  DEVIS_BY_NIVEAU(state, response): void {
    state.devisByNiveau=response.data
    localStorage.setItem('devisByNiveau',JSON.stringify(response.data))
  }
}
