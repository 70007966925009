




import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Chart } from 'chart.js'

@Component
export default class LineChart extends Vue {
  @Prop() title!: string
  @Prop() labels!: Array<string>
  @Prop() data!: Array<number>

  @Watch('data')
  dataChange() {
    this.createChart({
      labels: this.labels,
      datasets: [
        {
          label: this.title,
          data: this.data,
          backgroundColor: '#67afb1',
          borderWidth: 0
        }
      ]
    })
  }

  mounted(): void {
    this.createChart({
      labels: this.labels,
      datasets: [
        {
          label: this.title,
          data: this.data,
          backgroundColor: '#67afb1',
          borderWidth: 0
        }
      ]
    })
  }

  createChart(chartData: object) {
    const canvas = document.getElementById('line') as HTMLCanvasElement
    const defaultOptions: object = {
        legend: {
          display: true,
          position: 'bottom'
        }
      }
    const options = {
      type: 'line',
      data: chartData,
      options: defaultOptions
    }
    new Chart(canvas, options)
  }
}
