import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './types'

import { admin } from '@/stores/admin/index'
import { chargeUnite } from '@/stores/chargeUnite/index'
import { commande } from '@/stores/commande/index'
import { etablissement } from '@/stores/etablissement/index'
import { user } from '@/stores/user/index'
import { document } from '@/stores/document/index'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.0'
  },
  modules: {
    admin,
    chargeUnite,
    commande,
    etablissement,
    document,
    user
  }
}

export default new Vuex.Store<RootState>(store)
