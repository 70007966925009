import { EtablissementState } from './types'
import { ActionTree } from 'vuex'
import { RootState } from '@/stores/types'

import activitesApi from '@/api/activites.api'
import etablissementApi from '@/api/etablissement.api'
import elevesApi from '@/api/eleves.api'

import { Etablissement } from '@/models/Etablissement'

export const actions: ActionTree<EtablissementState, RootState> = {
  getGroupement({ commit }, login: string): Promise<void|{}> {
    return etablissementApi.getGroupement(login).then((groupementResponse) => {
      commit('GET_GROUPEMENT', groupementResponse)
    })
  },
  setEtablissement({ commit }, etablissement: Etablissement): void {
    commit('SET_ETABLISSEMENT_FROM_ADMIN', etablissement)
  },
  getEtablissements({ commit }): Promise<void|{}> {
    return etablissementApi.getEtablissements().then((etablissementResponse) => {
      commit('GET_ETABLISSEMENTS', etablissementResponse)
    })
  },
  getEtablissement({ commit }, login: string): Promise<void|{}> {
    return etablissementApi.getEtablissement(login).then((etablissementResponse) => {
      commit('SET_ETABLISSEMENT', etablissementResponse)
    })
  },
  getEtablissementParUnite({ commit }, unite: string): Promise<void|{}> {
    return etablissementApi.getEtablissementsParUnite(unite).then((etablissementResponse) => {
      commit('GET_ETABLISSEMENTS', etablissementResponse)
    })
  },
  getEtablissementParGroupement({ commit }, groupement: string): Promise<void|{}> {
    return etablissementApi.getEtablissementsParGroupement(groupement).then((etablissementResponse) => {
      commit('GET_ETABLISSEMENTS', etablissementResponse)
    })
  },
  getStatsChronorelaisParEtablissement({ commit }, groupementId: string): Promise<void|{}> {
    return etablissementApi.getStatsChronorelaisParEtablissement(groupementId)
  },
  getDistribStatsParEtablissement({ commit }, groupementId: string): Promise<void|{}> {
    return etablissementApi.getDistribStatsParEtablissement(groupementId)
  },
  getNiveaux({ commit }, groupement: string): Promise<void|{}> {
    return elevesApi.getEtablissementNiveaux(groupement).then((elevesResponse) => {
      commit('GET_NIVEAUX', elevesResponse)
    })
  },
  clearNiveaux({ commit }): void {
    commit('CLEAR_NIVEAUX')
  }
}
