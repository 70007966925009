import { MutationTree } from 'vuex'
import { AdminState } from './types'

export const mutations: MutationTree<AdminState> = {
  GET_GROUPEMENTS_ADMIN(state, response): void {
    state.groupementsAdmin = response.data
    localStorage.setItem('groupementsAdmin', JSON.stringify(response.data))
  },
  LOGOUT(state): void {
    state.groupementsAdmin = []
  },
  SET_ADMIN_REQUEST(state, param: {}): void {
    state.searchRequestAdmin = param
    localStorage.setItem('searchRequestAdmin', JSON.stringify(param))
  }
}
