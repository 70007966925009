import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { Module } from 'vuex'
import { RootState } from '@/stores/types'
import { UserState } from './types'

export const state: UserState = {
  username: '',
  accessToken: '',
  refreshToken: '',
  tokenValidity: 0,
  role: '',
  unites: '',
  searchRequest: {}
}

const namespaced = true

export const user: Module<UserState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
