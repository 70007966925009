import {ActionTree} from 'vuex'
import {RootState} from '@/stores/types'
import DocumentApi from "@/api/document.api";
import {DocumentState} from "@/stores/document/types";

export const actions: ActionTree<DocumentState, RootState> = {
  uploadDocument({commit}, doc: { etablissement: string; nom: string; file: File }): Promise<void | {}> {
    const formData = new FormData();
    formData.append("file", doc.file);
    formData.append("etablissement", doc.etablissement);
    formData.append("nom", doc.nom);
    return DocumentApi.uploadDocument(formData)
  },
  getDocuments({commit}, data: { etablissements: string }): Promise<void | {}> {
    return DocumentApi.getDocuments(data);
  },
  deleteDocument({commit}, data: { id: string }): Promise<void | {}> {
    return DocumentApi.deleteDocument(data);
  },
  editDocument({commit}, data: { id: string; nom: string; etablissements: string }): Promise<void | {}> {
    return DocumentApi.editDocument(data);
  },
  seeDocument({commit}, data: { id: string; etablissement: string }): Promise<void | {}> {
    return DocumentApi.seeDocument(data);
  },
  notifsDocuments({commit}, data: { id: string }): Promise<void | {}> {
    return DocumentApi.notifsDocuments(data);
  }
}
