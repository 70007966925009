







import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Alert extends Vue {
  public snackbar?: boolean = false
  public message?: string = ''
  public color?: string = ''
  public timeout?: number = 6000

  public open(message: string, color = 'error', timeout = 0): void {
    this.snackbar = true
    this.message = message
    this.color = color
    this.timeout = timeout
  }
}
