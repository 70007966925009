























































import { Vue, Component } from 'vue-property-decorator'
import { Action,Getter } from 'vuex-class'
import Alert from '@/components/Alert.vue'

@Component({
  components: {
    Alert
  }
})
export default class RechercheEtablissementsAdmin extends Vue {
  @Action('rechercheEtablissement', { namespace: 'admin' }) public rechercheEtablissement!: Function
  @Getter('role', { namespace: 'user' }) public role?: string
  @Getter('unites', {namespace: 'chargeUnite'}) public unite?: string[]
  @Getter('searchRequestAdmin', {namespace: 'admin'}) public searchRequestAdmin?: any

  public $refs: any = {}
  public identifiant = ''
  public nom = ''
  public sansCommande = false
  public commandeNonLivree = false
  public reventeEnAttente = false

  mounted() {
    // 56500 - Récupération des critères de la recherche précédente
    this.identifiant = this.searchRequestAdmin.etablissementId
    this.sansCommande = this.searchRequestAdmin.no_command
    this.nom = this.searchRequestAdmin.nom
    this.commandeNonLivree = this.searchRequestAdmin.undelivered_command
    this.reventeEnAttente = this.searchRequestAdmin.undelivered_resale
  }

  public startSearch(): void {
    let requestSearch: any
    if(this.role === 'ADMIN')
    {
      requestSearch = {
      'etablissementId': this.identifiant,
      'no_command': this.sansCommande,
      'nom': this.nom,
      'undelivered_command': this.commandeNonLivree,
      'undelivered_resale': this.reventeEnAttente
     }
    }
    else if(this.role === 'CHARGE_UNITE')
    {
       requestSearch = {
      'etablissementId': this.identifiant,
      'no_command': this.sansCommande,
      'nom': this.nom,
      'undelivered_command': this.commandeNonLivree,
      'undelivered_resale': this.reventeEnAttente,
      'unites' : this.unite
     }
    }
    this.$emit('recherchesEtablissements', requestSearch)
  }
}
