import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { Module } from 'vuex'
import { RootState } from '@/stores/types'
import { CommandeState } from './types'

import { Commande } from '@/models/Commande'
import {Devis} from '@/models/Devis'
import {Livre} from '@/models/Livre'

export const state: CommandeState = {
  commandes: [],
  selectedCommande: new Commande,
  livres:[],
  devisByNiveau: new Devis
}

const namespaced = true

export const commande: Module<CommandeState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
