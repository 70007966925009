

























import FormMixin from '@/mixins/FormMixin';
import loginApi from '@/api/login.api';
import { Component } from 'vue-property-decorator'
import Alert from '@/components/Alert.vue'
import { Action } from 'vuex-class';

@Component({
  components: {
    Alert
  }
})

export default class ResetPassword extends FormMixin {
  @Action('logout', { namespace: 'user' }) public logout!: Function;

  public password = '';
  public passwordConfirmation = '';
  public loading = true;
  public sendLoading = false;
  public success = false;
  public errorMessage = '';
  public validForm = false;

  public $refs: any = {
    resetPasswordForm: HTMLFormElement
  }

  public created(): void {
    loginApi.resetPasswordKeyExist(this.$route.params.key).then(response => {
      this.logout();
      this.loading = false;
    }).catch(({ response }) => {
      this.$router.push({ name: "Login" })
    })
  }

  public validate(): void {
    let error = false;
    const displayError = () => {
      if (error && this.errorMessage !== '') {
        this.sendLoading = false;
        this.$refs.snackbar.open(this.errorMessage)
      }
    }

    if (this.password != this.passwordConfirmation) {
      error = true;
      this.errorMessage = "Votre nouveau mot de passe et sa confirmation doivent être identique."
    }

    if (!error && this.$refs.resetPasswordForm.validate()) {
      this.sendLoading = true;

      loginApi.useResetPasswordKey(this.$route.params.key, this.password).then(response => {
        this.success = true;
        this.$refs.snackbar.open("Nouveau mot de passe enregistré.", "success");
      }).catch(() => {
        error = true;
        this.errorMessage = 'Une erreur est survenue.';
      }).finally(displayError)
    }

    displayError();
  }
}

