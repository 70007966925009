import { http } from './http'

export default {
  filtreCommande(annee: string, groupementId: string, niveau: string, prenom: string, nom: string, statutLivraison: string, statutRevente: string): Promise<{}> {
    const params = new URLSearchParams();
    if (annee) params.append("annee", annee);
    if (groupementId) params.append("groupement", groupementId);
    if (niveau) params.append("niveau", niveau);
    if (prenom) params.append("prenom", prenom);
    if (nom) params.append("nom", nom);
    if (statutLivraison) params.append("statutLivraison", statutLivraison);
    if (statutRevente) params.append("statutRevente", statutRevente);
    return http.post('/commande/', params)
  },
  livresParIdCommande(commandeId: string): Promise<{}> {
    const params = new URLSearchParams();
    params.append("commandeId", commandeId);
    return http.post('/commande/livres', params)
  },

  xls(annee: string, groupementId: string, niveau: string, prenom: string, nom: string, statutLivraison: string, statutRevente: string): Promise<{}> {
    const params = new URLSearchParams();
    if (annee) params.append("annee", annee);
    if (groupementId) params.append("groupement", groupementId);
    if (niveau) params.append("niveau", niveau);
    if (prenom) params.append("prenom", prenom);
    if (nom) params.append("nom", nom);
    if (statutLivraison) params.append("statutLivraison", statutLivraison);
    if (statutRevente) params.append("statutRevente", statutRevente);
    return http.post('/commande/export/xls', params, { responseType: "blob" })
  },
 
}
