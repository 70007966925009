import { Eleve } from '@/models/Eleve'

export class Commande {
  constructor(
    public statut_livraison?: number,
    public statut_revente?: number,
    public numeroCommande?: number,
    public annee_scolaire?: string,
    public date_commande?: Date,
    public mode_livraison?: string,
    public packType?: string,
    public statut_solde_livraison?: number,
    public eleve?: Eleve,
  ) { }
}
